import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-san-mateo-california.png'
import image0 from "../../images/cities/scale-model-of-la-honda-winery-in-san-mateo-california.png"
import image1 from "../../images/cities/scale-model-of-city-of-burlingame-shorebird-sanctuary-in-san-mateo-california.png"
import image2 from "../../images/cities/scale-model-of-landing-green-park-in-san-mateo-california.png"
import image3 from "../../images/cities/scale-model-of-san-mateo-county-history-museum-in-san-mateo-california.png"
import image4 from "../../images/cities/scale-model-of-filoli-in-san-mateo-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='San Mateo'
            state='California'
            image={image}
            lat='37.5603'
            lon='-122.3106'
            attractions={ [{"name": "La Honda Winery", "vicinity": "2645 Fair Oaks Ave, Redwood City", "types": ["restaurant", "food", "point_of_interest", "establishment"], "lat": 37.4816889, "lng": -122.20930529999998}, {"name": "City of Burlingame Shorebird Sanctuary", "vicinity": "Between 1404 and 1500, Old Bayshore Hwy, Burlingame", "types": ["park", "point_of_interest", "establishment"], "lat": 37.5974855, "lng": -122.3658618}, {"name": "Landing Green Park", "vicinity": "200 E 28th Ave, San Mateo", "types": ["park", "point_of_interest", "establishment"], "lat": 37.5434984, "lng": -122.2993356}, {"name": "San Mateo County History Museum", "vicinity": "2200 Broadway, Redwood City", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.48699149999999, "lng": -122.2296488}, {"name": "Filoli", "vicinity": "86 Ca\u00f1ada Road, Woodside", "types": ["museum", "park", "store", "point_of_interest", "establishment"], "lat": 37.4704708, "lng": -122.31067640000003}] }
            attractionImages={ {"La Honda Winery":image0,"City of Burlingame Shorebird Sanctuary":image1,"Landing Green Park":image2,"San Mateo County History Museum":image3,"Filoli":image4,} }
       />);
  }
}